import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { IoService } from 'src/app/_services/io.service';
import { MessengerService } from 'src/app/_services/messenger.service';
import { StudentService } from 'src/app/_services/student.service';
import { environment } from 'src/environments/environment';
import { SchoolService } from 'src/app/_services/school.service';

@Component({
  selector: 'app-class-history-compose',
  templateUrl: './class-history-compose.component.html',
  styleUrls: ['./class-history-compose.component.css']
})
export class ClassHistoryComposeComponent implements OnInit {
  categorys = []; // lớp
  class_selected : string= ""; // select lớp
  checkboxAllButton = true; // mặc định chọn all
  discussion ={
    recipient:'',
    title:'',
    message: '',
    file_url: '',
    type: environment.message_type_class_history,
  };
  isLoading = false;

  fileAttach: File;

  imageUrl: any = environment.image_frame;
  
  // upload multiFile
  imagePreviews: string[] = [];
  selectedFiles: File[] = [];
  constructor(
    public schoolService: SchoolService,
    public categoryService: CategoryEduService,
    public studentService: StudentService,
    private toastr: ToastrService,
    public messengerService: MessengerService,
    public dialogRef: MatDialogRef<ClassHistoryComposeComponent>,
    public appService: AppService,
    public dialog: MatDialog,
    public ioService: IoService,
  ) {
    // this.discussion.title = this.appService.currentUser.school_name ;
    // this.discussion.message = this.appService.currentUser.school_name ;
    this.appService.page_title = "Nhật ký lớp học";
    this.loadCategory();
  
  }

  ngOnInit(): void {
  }

  checkboxAll(evt) {
    console.log(this.checkboxAllButton);
    console.log(evt.target.checked);
    for (let index = 0; index < this.categorys.length; index++) {
      this.categorys[index].temp_checked = evt.target.checked;
    }
    
  }
  updateDiscussionRecipient(){
    this.discussion.recipient = '';
    this.categorys.forEach(item => {
      if(item.temp_checked == 1){
        this.discussion.recipient += item.code + ",";
      }
    });
   
  }
  confirmSendMessenger(){
    let data = {
      title : 'Xác nhận gửi nội dung',
      msg : 'Đồng ý đề bắt đầu gửi nhật ký lớp học .',
    }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'ok'){
          // close hoặc không close
          this.send();
      
        }
      });
  }
  send(){
    // cập nhật danh sách lớp cho discussion.recipient
    // this.updateDiscussionRecipient();
    this.discussion.recipient = this.class_selected;
    
    if(this.discussion.recipient == ""){
      this.toastr.warning("Thầy cô vui lòng chọn lớp","");
      return;
    }
    // xử lý file đính kèm
    // let filename = '';
    // if (this.fileAttach !== null && typeof this.fileAttach !== 'undefined') {
    //   filename = this.appService.currentUser.code + "_" + Date.now() + "."+ this.fileAttach.name.split('.').pop();
    //   this.discussion.file_url = environment.class_history + "/" + filename;
    //   console.log("1111");
    // } else {
    //   console.log("2222");
    // }

    this.messengerService.sendMessenger(this.discussion).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (typeof response != "undefined" && response.code == "ok") {
          // nếu có file đính kèm thì upload
          if(this.selectedFiles.length > 0)
          this.uploadFiles(this.selectedFiles, environment.class_history, response.id);
          // this.uploadFileAttach(this.fileAttach, environment.class_history, filename.split('.').slice(0, -1).join('.'));
          // nếu có file đính kèm

          // đóng diaglog
          let result = {
            code: "ok",
            data: {}
          }
          this.dialogRef.close(result);
        }
      },
      error => {
        this.isLoading = false;
        console.error("error:");
        console.error(error);
      })
    console.log(this.discussion);
  }
  loadCategory() {


    let filter = {
      khoi_code: '',
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.categoryService.getClassByGVCNCode(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          response.forEach(element => {
            // gán class_selected mặc định
            if(this.class_selected == ''){
                this.class_selected = element.code;
            }
          });
          this.categorys = response;
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })


  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      // this.imagePreviews = []; // Reset trước khi thêm mới
      Array.from(input.files).forEach((file) => {
        this.selectedFiles.push(file); // thêm vào mảng file
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviews.push(e.target?.result as string);
        };
        reader.readAsDataURL(file);
      });
    }
  }
  removeImage(index: number) {
    this.imagePreviews.splice(index, 1);
    this.selectedFiles.splice(index, 1);
  }


  async filePickerListener($event): Promise<void> {
    this.fileAttach = $event.target.files[0];
    if (this.fileAttach == null) {
      return;
    }
    
    var reader = new FileReader();
    reader.readAsDataURL(this.fileAttach);
    reader.onload = (_event) => {
      this.imageUrl = reader.result;
    }

    console.log('originalFile fileAttach Blob', this.fileAttach instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileAttach.size / 1024} KB`);
    
  }
  uploadFiles(uploadFiles: File[], des: string, id: string) {
    
    if (uploadFiles.length == 0) {
      return;
    }

    const formData = new FormData();
    uploadFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('type', des);
    formData.append('des', des);
    formData.append('id', id);


    this.ioService.uploadFiles(formData).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  
}
