<div class="page-content fade-in-up">


    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                <mat-label>Tìm kiếm</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Tìm kiếm công việc" name="search" maxlength="200">
                <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </a>
            </mat-form-field>


            <mat-form-field appearance="fill" style="width: 180px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Giáo viên</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="filter.teacher_code" (selectionChange)="this.loadCategory();">
                    <mat-option [value]="" [disabled]="false">
                        Tất cả
                    </mat-option>
                    <mat-option *ngFor="let item of teachers" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                <mat-label>Sắp xếp</mat-label>
                <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="this.loadCategory();">
                    <mat-option *ngFor="let item of appService.sort_category" [value]="item.code">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="col-3">
            <div class="d-flex justify-content-end">
                <div class="p-2"> <button class="btn  btn-primary btn-labeled btn-labeled-left btn-icon" data-toggle="modal" data-target="#add-customer-modal" (click)="popup_category_add()">
                        <span class="btn-label"><i class="ti ti-plus"></i></span>Thêm mới
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-2 text-right">
        <div class="btn-group">
            <div class="btn" [ngClass]="{'btn-primary': filter.status == -1, 'btn-outline-primary': filter.status != -1}" (click)="status_change(-1)">
                Tất cả
            </div>
            <div class="btn" [ngClass]="{'btn-primary': filter.status == 0, 'btn-outline-primary': filter.status != 0}" (click)="status_change(0)">
                <span class="material-icons">
                    assignment
                    </span>Bản nháp
            </div>
            <div class="btn" [ngClass]="{'btn-primary': filter.status == 1, 'btn-outline-primary': filter.status != 1}" (click)="status_change(1)">
                <span class="material-icons">
                    send
                    </span>Đã gửi
            </div>

            <div class="btn" [ngClass]="{'btn-primary': filter.status == 2, 'btn-outline-primary': filter.status != 2}" (click)="status_change(2)">
                <span class="material-icons">
                    pending_actions
                    </span> Đang thực hiện
            </div>
            <div class="btn" [ngClass]="{'btn-primary': filter.status == 3, 'btn-outline-primary': filter.status != 3}" (click)="status_change(3)">
                <span class="material-icons">
                    assignment_turned_in
                    </span> Hoàn thành
            </div>
        </div>
    </div>



    <div class=" row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">#</th>
                    <th>Công việc</th>
                    <th class="text-center" style="min-width: 205px;">Thời gian</th>
                    <!-- <th class="text-center">Số ngày</th>
                    <th class="text-center">Còn lại</th> -->
                    <th class="text-center">File</th>
                    <th class="text-center" style="min-width: 160px;">Trạng thái</th>
                    <th class="text-center">Người thực hiện/Kết quả</th>
                    <th class="no-sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of categorys; let i = index">
                    <td>
                        <a class="avatar-img-list" href="javascript:;">
                            <img class="img-circle mt-1" src="./assets/img/icons/checkin-time.png" alt="image" width="30" />
                        </a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                        <div style="font-size: 13px; font-weight: bold;" class="text-muted">
                            <span>{{item.code}}</span>
                            <span class="material-icons font-13">
                                fiber_manual_record
                                </span>
                            <span>{{item.teacher_host_name}}</span>
                        </div>
                    </td>
                    <td class="text-center">
                        <div><span>{{item.startTime_str}} - {{item.endTime_str}}</span></div>
                        <app-progress-bar [doing]="item.duration_day-item.duration_day_left" [left]="item.duration_day_left"></app-progress-bar>
                    </td>
                    <!-- <td class="text-center">
                        <span>{{item.duration_day}}</span>
                    </td>
                    <td class="text-center">
                        <span>{{item.duration_day_left}}</span>
                    </td> -->
                    <td class="text-center">
                        <button *ngIf="item.file_url_objs.length > 0" [ngbTooltip]="item.file_url_objs.length + ' file đính kèm'" class="btn btn-sm btn-white btn-icon-only  btn-thick mr-2" placement="top"   [ngClass]="{'text-primary' : true  }">
                            <span class="material-icons"> file_present </span>
                        </button>

                        <span *ngIf="item.file_url_objs.length == 0" class="btn btn-sm btn-white text-muted btn-icon-only  btn-thick" placement="top" ngbTooltip="Không có đính kèm" style="opacity: 0.7;"><span class="material-icons text-secondary">file_present</span></span>
                    </td>
                  
                    <td class="text-center status">

                        <button class="btn btn-sm btn-white btn-icon-only  btn-thick mr-2" placement="top" ngbTooltip="Bản nháp" (click)='popupStatus(item,0,"Bản thảo")' [ngClass]="{'text-primary' : item.status==0, 'text-secondary': item.status!=0 }">
                            <span class="material-icons"> assignment </span>
                        </button>

                        <button class="btn btn-sm btn-white btn-icon-only  btn-thick mr-2" placement="top" ngbTooltip="Đã gửi" (click)='popupStatus(item,1,"Đã gửi")' [ngClass]="{'text-primary' : item.status==1, 'text-secondary': item.status!=1  }">
                            <span class="material-icons "> send </span>
                        </button>
                        <button class="btn btn-sm btn-white btn-icon-only  btn-thick mr-2" placement="top" ngbTooltip="Đang thực hiện" [ngClass]="{'text-primary' : item.status==2, 'text-secondary': item.status!=2  }">
                            <span class="material-icons "> pending_actions </span>
                        </button>
                        <button class="btn btn-sm btn-white btn-icon-only  btn-thick" placement="top" ngbTooltip="Hoàn thành" (click)='popupStatus(item,3,"Hoàn thành")' [ngClass]="{'text-primary' : item.status==3, 'text-secondary': item.status!=3  }">
                            <span class="material-icons"> assignment_turned_in </span>
                        </button>
                    </td>
                    <td class="">
                        <ng-container *ngIf="item.status == 0">
                            <!-- Bản nháp -->
                            <button *ngFor="let teacher of item.teachers; let t = index" placement="top" ngbTooltip="Người thực hiện" class="btn btn-outline-dark btn-icon mr-1 mb-1">
                                {{teacher.teacher_name}}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="item.status > 0">
                            <ng-container *ngFor="let tResult of item.teachers; let j = index">
                                <button *ngIf="tResult.code == ''" (click)='popupResult(item, tResult)' [disabled]="!tResult.isAvailable" placement="top" [ngbTooltip]="'Chờ nhận'" class="btn btn-white  btn-outline-warning btn-icon mr-1 mb-1">
                                    <span class="btn-label"><span class="material-icons font-20"> pending_actions </span></span>
                                    {{tResult.teacher_name}}
                                </button>
                                <button *ngIf="tResult.code != '' && tResult.status == 0" (click)='popupResult(item,tResult)' [disabled]="!tResult.isAvailable" placement="top" [ngbTooltip]="'Đang làm'" class="btn btn-white btn-primary btn-icon mr-1 mb-1">
                                    <span class="btn-label"><span class="material-icons font-20"> pending_actions </span></span>
                                    {{tResult.teacher_name}}
                                </button>
                                <button *ngIf="tResult.code != '' && tResult.status == 1" (click)='popupResult(item,tResult)' [disabled]="!tResult.isAvailable" placement="top" [ngbTooltip]="'Đã nộp'" class="btn btn-white btn-outline-warning btn-icon mr-1 mb-1">
                                    <span class="btn-label"><span class="material-icons font-20"> assignment_turned_in </span></span>
                                    {{tResult.teacher_name}}
                                </button>
                                <button *ngIf="tResult.code != '' && tResult.status == 2" (click)='popupResult(item,tResult)' [disabled]="!tResult.isAvailable" placement="top" [ngbTooltip]="'Hoàn thành'" class="btn btn-white btn-primary btn-icon mr-1 mb-1">
                                    <span class="btn-label"><span class="material-icons font-20"> assignment_turned_in </span></span>
                                    {{tResult.teacher_name}}
                                </button>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-sm btn-white btn-icon-only  btn-thick" placement="top" ngbTooltip="Chỉnh sửa" (click)='popupUpdate(item)'>
                            <span class="material-icons"> settings </span>
                        </button>
                    </td>

                </tr>
            </tbody>
        </table>


    </div>
    <div *ngIf="!isLoading && categorys.length == 0" class="text-center">
        <app-nodata msg="Không có dữ liệu giao việc nhân viên"></app-nodata>
    </div>
    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>




<div class="modal fade" id="add-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #addForm="ngForm" id="form-add-category">
            <div class="modal-header p-4">
                <h5 class="modal-title">Thêm mới đầu việc</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Tiêu đề</mat-label>
                    <input matInput [(ngModel)]="cate.name" placeholder="Nhập tiêu đề" name="name" required minlength="2" maxlength="200">
                </mat-form-field>

                <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Nội dung</mat-label>
                    <textarea matInput [(ngModel)]="cate.khoi" rows="3" name="khoi" maxlength="500" placeholder="Nội dung"></textarea>
                </mat-form-field>

                <div class="form-field-cover mb-2">
                    <div class="mb-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="font-14 ">Người thực hiện:</span> 
                            <a (click)='openSelectTeacherMore_create()' class="btn btn-sm btn-white btn-icon-only btn-circle btn-thick" placement="top" ngbTooltip="Chọn giáo viên"  >
                                <span class="material-icons"> edit_note </span>
                            </a>
                        </div>
                    </div>
                    <div>
                        <span class="badge-teacher-cover" *ngFor="let item of teacherSelecteds; let i = index">
                            <span class="badge badge-primary" >{{item.name}}</span>
                            <a class="action">
                                <button class="btn btn-sm btn-white btn-icon-only btn-circle btn-thick" (click)='deleteTeacher(i)'>
                                    <span class="material-icons"> close </span>
                                </button>
                            </a>
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col pl-0">

                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput [matDatepicker]="picker_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_startTime" name="hockyI_startTime" required (dateChange)="onDateSelect($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker_startTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_startTime></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày kết thúc</mat-label>
                            <input matInput [matDatepicker]="picker_endTime" placeholder="DD/MM/YYYY" [(ngModel)]="cate.hockyI_endTime" name="hockyI_endTime" required (dateChange)="onDateSelect($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker_endTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_endTime></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-2 pr-0">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số ngày</mat-label>
                            <input matInput [(ngModel)]="songay" name="songay">
                        </mat-form-field>
                    </div>
                </div>



                

                <div class="upload-container">
                      <div *ngFor="let item of selectedFiles" class="d-flex justify-content-between align-items-center preview-container">
                        <div> <span class="material-icons"> attach_file </span> {{item.name}}</div>
                        <button class="btn btn-sm btn-danger btn-icon-only btn-circle btn-thick" (click)='removeImage(i)'>
                            <span class="material-icons"> close </span>
                        </button>
                      </div>
                </div>


                <div class="filettach-upload mb-3 mt-3">
                    Đính kèm file:
                    <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                        id="fileAttach" name="fileAttach" (change)="onFileSelected($event)">
                </div>
            </div>
            <div class="modal-footer justify-content-around bg-primary-50">

                <button class="btn btn-primary   btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.addForm.invalid" (click)="insertCategory()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>



<div class="modal fade" id="update-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #updateForm="ngForm" id="form-update-category">
            <div class="modal-header p-4">
                <h5 class="modal-title">Cập nhật công việc</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>Tiêu đều</mat-label>
                    <input matInput [(ngModel)]="categoryselected.name" placeholder="Nhập tên ca" name="u_name" required minlength="2" maxlength="200">
                </mat-form-field>
                <mat-form-field class="example-full-width mb-2" appearance="fill">
                    <mat-label>Nội dung</mat-label>
                    <textarea matInput [(ngModel)]="categoryselected.khoi" rows="3" name="u_khoi" maxlength="500" placeholder="Nội dung"></textarea>
                </mat-form-field>
                <div class="form-field-cover mb-2">
                    <div class="mb-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="font-14 ">Người thực hiện:</span> 
                            <a (click)='openSelectTeacherMore(categoryselected)' class="btn btn-sm btn-white btn-icon-only btn-circle btn-thick" placement="top" ngbTooltip="Chọn giáo viên"  >
                                <span class="material-icons"> edit_note </span>
                            </a>
                        </div>
                    </div>
                    <div>
                        <button *ngFor="let teacher of categoryselected.teachers; let t = index" placement="top" ngbTooltip="Người thực hiện" class="btn btn-outline-primary btn-icon mr-1 mb-1">
                            <span class="btn-label"><span class="material-icons font-18"> assignment_ind </span></span>
                            {{teacher.teacher_name}}
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col pl-0">


                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày bắt đầu</mat-label>
                            <input matInput [matDatepicker]="picker_u_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="categoryselected.hockyI_startTime" name="u_startTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_u_startTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_u_startTime></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="col">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Ngày kết đầu</mat-label>
                            <input matInput [matDatepicker]="picker_u_endTime" placeholder="DD/MM/YYYY" [(ngModel)]="categoryselected.hockyI_endTime" name="u_endTime" required>
                            <mat-datepicker-toggle matSuffix [for]="picker_u_endTime"></mat-datepicker-toggle>
                            <mat-datepicker #picker_u_endTime></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-2 pr-0">
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Số ngày</mat-label>
                            <input matInput [(ngModel)]="categoryselected.duration_day_left" name="u_songay">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <span class="btn-white" placement="top">
                        File đính kèm: 
                    </span>
                </div>

                <!-- Upload cũ  -->
                <div class="upload-container">
                    <div *ngFor="let item of categoryselected.file_url_objs ; let i = index" class="d-flex justify-content-between align-items-center preview-container">
                        <div> <a [href]="item.fileUrl" target="_blank"><span class="material-icons"> file_download </span></a> {{item.fileName}}</div>
                        <button class="btn-remove btn btn-sm btn-danger btn-icon-only btn-circle btn-thick" (click)='removeImageUploaded(i, categoryselected.file_url_objs)'>
                            <span class="material-icons"> close </span>
                        </button>
                    </div>
                </div>
                <!-- Upload mới -->
                <div class="upload-container">
                        <div *ngFor="let item of selectedFiles ; let i = index" class="d-flex justify-content-between align-items-center preview-container">
                        <div> <span class="material-icons"> attach_file </span> {{item.name}}</div>
                        <button class="btn btn-sm btn-danger btn-icon-only btn-circle btn-thick" (click)='removeImage(i)'>
                            <span class="material-icons"> close </span>
                        </button>
                        </div>
                </div>


                <div class="filettach-upload mb-3 mt-3">
                    Đính kèm file:
                    <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                        id="fileAttach" name="fileAttach" (change)="onFileSelected($event)">
                </div>

                <!-- <div class="filettach-upload mb-3 mt-3">
                    Đính kèm file:
                    <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                        id="u_fileAttach" name="u_fileAttach" (change)="u_filePickerListener($event)">
                </div> -->


                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Xóa</label>
                    <div class="col-sm-9 d-flex align-items-end">
                        <div class="col text-right">
                            <label class="ui-switch switch-icon">
                                <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="true">
                                <!-- <input type="checkbox" [(ngModel)]="isDeleted" name="deleted"
                                    [disabled]="categoryselected.code == 'DM'"> -->
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon"  (click)="updateCategory()" [disabled]="isLoading || this.updateForm.invalid">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-check"></i></span>
                    Lưu
                </button>
                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>

<div class="modal fade" id="result-category-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" #resultForm="ngForm" id="form-result-category">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập kết quả công việc</h5>
                <a data-dismiss="modal"><span class="material-icons"> close </span></a>
            </div>
            <div class="modal-body p-4">


                <mat-accordion>
                    <mat-expansion-panel [expanded]="tResult.code == ''">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Nội dung công việc </mat-panel-title>
                            <mat-panel-description> </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-form-field class="mb-3" appearance="fill">
                            <mat-label>Tiêu đều</mat-label>
                            <input matInput [(ngModel)]="categoryselected.name" placeholder="Nhập tên ca" name="r_name" readonly>
                        </mat-form-field>
                        <mat-form-field class="example-full-width mb-2" appearance="fill">
                            <mat-label>Nội dung</mat-label>
                            <textarea matInput [(ngModel)]="categoryselected.khoi" rows="3" name="r_khoi" readonly></textarea>
                        </mat-form-field>
                        <div class="row">
                            <div class="col pl-0">
                                <mat-form-field class="mb-3" appearance="fill">
                                    <mat-label>Ngày bắt đầu</mat-label>
                                    <input matInput placeholder="DD/MM/YYYY" [(ngModel)]="categoryselected.startTime_str" name="r_startTime" readonly>
                                    <!-- <mat-datepicker-toggle matSuffix [for]="picker_u_startTime"></mat-datepicker-toggle>
                                    <mat-datepicker #picker_u_startTime></mat-datepicker> -->
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field class="mb-3" appearance="fill">
                                    <mat-label>Ngày kết đầu</mat-label>
                                    <input matInput placeholder="DD/MM/YYYY" [(ngModel)]="categoryselected.endTime_str" name="r_endTime" readonly>
                                    <!-- <mat-datepicker-toggle matSuffix [for]="picker_u_endTime"></mat-datepicker-toggle>
                                    <mat-datepicker #picker_u_endTime></mat-datepicker> -->
                                </mat-form-field>
                            </div>
                            <div class="col-2 pr-0">
                                <mat-form-field class="mb-3" appearance="fill">
                                    <mat-label>Số ngày</mat-label>
                                    <input matInput [(ngModel)]="categoryselected.duration_day_left" name="u_songay">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <span class="btn-white text-muted ">
                                File đính kèm: 
                            </span>
                        </div>
                        <!--  -->
                        <div class="upload-container">
                            <div *ngFor="let item of categoryselected.file_url_objs ; let i = index" class="d-flex justify-content-between align-items-center preview-container">
                                <div> <a [href]="item.fileUrl" target="_blank"><span class="material-icons"> file_download </span></a> {{item.fileName}}</div>
                            </div>
                        </div>

                    </mat-expansion-panel>
                    <mat-expansion-panel *ngIf="tResult.code != ''" [expanded]="tResult.code != ''">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Kết quả công việc </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="result-cover">
                            <mat-form-field class="example-full-width mb-2" appearance="fill">
                                <mat-label>Nội dung</mat-label>
                                <textarea matInput [(ngModel)]="categoryselected_TR.khoi" rows="3" name="tr_khoi" maxlength="500" placeholder="Nội dung"></textarea>
                            </mat-form-field>

                            <div class="row">
                                <span class="btn-white" >File đính kèm: </span>
                            </div>

                            <!-- Upload cũ  -->
                            <div class="upload-container">
                                <div *ngFor="let item of categoryselected_TR.file_url_objs ; let i = index" class="d-flex justify-content-between align-items-center preview-container">
                                    <div> <a [href]="item.fileUrl" target="_blank"><span class="material-icons"> file_download </span></a> {{item.fileName}}</div>
                                    <!-- Kiểm tra quyền xóa: Người nhận mới được xóa, và trạng thái nháp thì được xóa -->
                                    <button *ngIf="categoryselected_TR.user_code == appService.currentUser.code && tResult.status == 0" class="btn-remove btn btn-sm btn-danger btn-icon-only btn-circle btn-thick" (click)='removeImageUploaded(i, categoryselected_TR.file_url_objs)'>
                                        <span class="material-icons"> close </span>
                                    </button>
                                </div>
                            </div>
                            <!-- Upload mới -->
                            <div class="upload-container">
                                    <div *ngFor="let item of selectedFiles_TR ; let i = index" class="d-flex justify-content-between align-items-center preview-container">
                                    <div> <span class="material-icons"> attach_file </span> {{item.name}}</div>
                                    <button class="btn btn-sm btn-danger btn-icon-only btn-circle btn-thick" (click)='removeImage_TR(i)'>
                                        <span class="material-icons"> close </span>
                                    </button>
                                    </div>
                            </div>


                            <div *ngIf="categoryselected_TR.user_code == appService.currentUser.code" class="filettach-upload mb-3 mt-3">
                                Đính kèm file:
                                <input type="file" accept="image/jpeg,image/gif,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                                    id="fileAttach" name="fileAttach" (change)="onFileSelected_TR($event)">
                            </div>


                        </div>
                    </mat-expansion-panel>
                </mat-accordion>






            </div>
            <div class="modal-footer justify-content-around bg-primary-50">
                <ng-container *ngIf="tResult.status != 1">
                    <ng-container *ngIf="tResult.code == ''">
                        <button class="btn btn-warning  btn-labeled btn-labeled-left btn-icon" (click)="createResult(categoryselected)">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> pending_actions </span></span>
                        Nhận việc
                        </button>
                    </ng-container>
                    <ng-container *ngIf="tResult.code != '' && tResult.status >= 0">
                        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="updateResult(0)">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> pending_actions </span></span>
                        Cập nhật kết quả
                        </button>
                        <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="updateResult(1)">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> assignment_turned_in </span></span>
                        Cập nhật và gửi
                        </button>
                    </ng-container>
                </ng-container>
                <!-- Công việc phải chưa hoàn thành và kết quả phải là đã gửi -->
                <ng-container *ngIf="categoryselected.user_code == appService.currentUser.code && categoryselected.status != 3 && tResult.status == 1">
                    <button class="btn btn-danger  btn-labeled btn-labeled-left btn-icon" (click)="updateResult(0)">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> pending_actions </span></span>
                    Từ chối kết quả
                    </button>
                    <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="updateResult(2)">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> assignment_turned_in </span></span>
                    Xác nhận hoàn thành
                    </button>
                </ng-container>

                <button data-dismiss="modal" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
                    <span class="btn-label"><span class="material-icons"> close </span></span>
                    Đóng
                </button>
            </div>
        </form>
    </div>
</div>