<div id="sidebar-collapse">


    <div class="d-flex align-items-center  justify-content-center" style="background: white; font-size: 19px; height: 48px; padding: 5px;border-bottom: 1px solid #30435747;">
        <a class="nav-link sidebar-toggler js-sidebar-toggler">
            <span class="material-icons">
                close
            </span>
        </a>
        <div style="width: 100%; text-align: center;">
            <img style="width: 40px; padding-right: 10px;" src="./assets/img/icons/logo.png" />
            <span class="">AIS Connect - Giải pháp quản lý giáo dục</span>
        </div>
        <span style=" font-size: small; margin-right: 8px;">v{{version}}</span>
    </div>




    <div class="side-menu qbs-scroll" metis-menu>


        <!-- <li class="heading"><span class="nav-label">Trang chủ, báo cáo</span></li> -->
        <button routerLink="/home" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/chart.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">cast_for_education</span> -->
                <div>Trang chủ</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Trang chủ
            </div>
        </button>
        <button routerLink="/schedule-daily" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/calendar.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">menu_book</span> -->
                <div>Lịch dạy</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Lịch dạy hằng ngày</div>
        </button>
        <button routerLink="/schedule-report" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/chart-assessment.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">menu_book</span> -->
                <div>Lịch dạy</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Báo
                Báo cáo lịch dạy</div>
        </button>
        <button routerLink="/health-report" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/chart-health.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">menu_book</span> -->
                <div>Sức khỏe</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                <span>Báo cáo sổ sức khỏe</span>
            </div>
        </button>

        <button routerLink="/checkin-teacher-report" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/checkin-teacher.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">how_to_reg</span> -->
                <div>Giáo viên</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Báo cáo điểm danh</div>
        </button>
        <button routerLink="/checkIn-report" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/checkin-student.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">how_to_reg</span> -->
                <div>Học sinh</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Báo cáo điểm danh</div>
        </button>
        <div>
            <button routerLink="/checkIn-unknown" class="btn  btn-white m-2 btn-animated fade-button">
                <div class="visible-content btn-icon">
                    <img src="./assets/img/icons/person-unknown.png" alt="image" width="40" />
                    <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                    <div>Người lạ</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                    Danh sách người lạ</div>
            </button>
            <button routerLink="/meal-report" class="btn  btn-white m-2 btn-animated fade-button">
                <div class="visible-content btn-icon">
                    <img src="./assets/img/icons/chart-meal.png" alt="image" width="40" />
                    <div>Báo ăn</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                    Báo cáo ăn bán trú</div>
            </button>
        </div>
        <li class="heading"><span class="nav-label">Giáo viên, học sinh</span></li>
        <button routerLink="/teacher" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/teachers.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">badge</span> -->
                <div>Giáo viên</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Danh sách Giáo viên</div>
        </button>
        <button routerLink="/student" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/students.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">badge</span> -->
                <div>Học sinh</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Danh sách Học sinh</div>
        </button>
        <button routerLink="/checkIn" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/to-do-list.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">how_to_reg</span> -->
                <div>Điểm danh</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Điểm danh nhận xét ngày</div>
        </button>
        <button routerLink="/meal" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/meal.png" alt="image" width="40" />
                <div>Báo ăn</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                Quản lý ăn bán trú</div>
        </button>
        <button routerLink="/assessment" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/dictionary.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">task_alt</span> -->
                <div>Đánh giá</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">Sổ
                đánh giá môn học</div>
        </button>
        <button routerLink="/assessment-student" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/medal.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">task_alt</span> -->
                <div>Tổng kết</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Đánh giá học sinh</div>
        </button>


        <li class="heading"><span class="nav-label">Sổ sách</span></li>
        <button routerLink="/assessment-period" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Sổ điểm</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Sổ
                điểm theo đợt</div>
        </button>
        <button routerLink="/health-record" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/medical-report.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Sức khỏe</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Sổ
                sức khỏe</div>
        </button>

        <button routerLink="/application" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/contract.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Nghỉ phép</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                Giấy nghỉ phép HS</div>
        </button>
        <button routerLink="/task" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/to-do-list.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Giao việc</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                Giao việc nhân viên</div>
        </button>
        <button routerLink="/class-history" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/group.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Nhật ký</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                Nhật ký lớp học</div>
        </button>
        <button routerLink="/equipment" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/equipment.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Thiết bị</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                Danh sách thiết bị</div>
        </button>



        <li class="heading"><span class="nav-label">Quản lý thu chi</span></li>
        <button [disabled]="!appService.checkFinancePermission()" routerLink="/finance-cate" class="btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                <div>Khoản thu</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Khai báo khoản thu</div>
        </button>
        <button [disabled]="!appService.checkFinancePermission()" routerLink="/finance-assignment" class="btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/finance.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                <div>Gán K.thu</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Gán khoản thu HS</div>
        </button>
        <button [disabled]="!appService.checkFinancePermission()" routerLink="/finance-report" class="btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/finance-report.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                <div>Báo cáo</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Báo cáo khoản thu</div>
        </button>
        <button routerLink="/meal-report-month" class="btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/finance-meal.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                <div>Bán trú</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Khoản thu bán trú</div>
        </button>
        <button [disabled]="!appService.checkFinancePermission()" routerLink="/finance-banking" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/finance-concile.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">work</span> -->
                <div>Đối soát</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Đối soát Ngân hàng</div>
        </button>


        <li class="heading"><span class="nav-label">Tiện ích</span></li>
        <button routerLink="/discussion" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/discussion.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Liên lạc</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                Liên lạc, thông báo</div>
        </button>

        <button [disabled]="false" routerLink="/schedule-meal" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/meal-schedule.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Thực đơn</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Xếp
                thực đơn bán trú</div>
        </button>
        <button [disabled]="!appService.checkFullPermission()" routerLink="/schedule-static" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/calendar.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Thời KB</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Xếp
                thời khóa biểu</div>
        </button>
        <button [disabled]="false" routerLink="/teaching-schedule" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/calendar.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Xếp lịch</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Xếp
                lịch dạy</div>
        </button>

        <button [disabled]="true" routerLink="/teaching-schedule" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/book.png" alt="image" width="40" />
                <div>Báo giảng</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Nộp
                báo giảng</div>
        </button>
        <button [disabled]="false" routerLink="/online-schedule" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/online-schedule.png" alt="image" width="40" />
                <div>Lịch online</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">
                Khai báo lich học Online</div>
        </button>
        <!-- <button [disabled]="true" routerLink="/assessment-report" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/online-report.png" alt="image" width="40" />
                <div>Báo cáo</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Báo
                cáo học Online</div>
        </button> -->
        <li class="heading"><span class="nav-label">Khai báo dữ liệu</span></li>

        <button routerLink="/teachingAssignment" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">work</span> -->
                <div>PCGD</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Phân công giảng dạy</div>
        </button>
        <button routerLink="/class" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material">class</span> -->
                <div>Lớp</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                Khai báo lớp</div>
        </button>
        <button routerLink="/faculty" class=" btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">groups</span> -->
                <div>Tổ bộ môn</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Khai báo tổ bộ môn</div>
        </button>
        <button routerLink="/subjects" class="btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">book</span> -->
                <div>Môn học</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Khai báo môn</div>
        </button>
        <button routerLink="/assessment-setting" class="btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                <div>Đánh giá</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Khai báo đánh giá</div>
        </button>
        <button routerLink="/health-cate" class=" btn  btn-white m-2 btn-animated fade-button ">
            <div class="visible-content btn-icon ">
                <img src="./assets/img/icons/cate-health.png" alt="image" width="40" />
                <!-- <span class="material-icons font-30 sidebar-item-icon-material ">groups</span> -->
                <div>Sức khỏe</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                Danh mục sức khỏe</div>
        </button>
        <div>
            <button routerLink="/equipment-cate" class=" btn  btn-white m-2 btn-animated fade-button ">
                <div class="visible-content btn-icon ">
                    <img src="./assets/img/icons/equipment-cate.png" alt="image" width="40" />
                    <!-- <span class="material-icons font-30 sidebar-item-icon-material ">groups</span> -->
                    <div>Thiết bị</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                    Danh mục thiết bị</div>
            </button>
            <button routerLink="/khoicoso" class="btn  btn-white m-2 btn-animated fade-button">
                <div class="visible-content btn-icon">
                    <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                    <!-- <span class="material-icons font-30 sidebar-item-icon-material">class</span> -->
                    <div>Khối/Cơ sở</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                    Khai báo Khối/Cơ sở</div>
            </button>
        </div>
        <div>
            <button [disabled]="!appService.checkFullPermission()" routerLink="/category-checkin-time" class="btn  btn-white m-2 btn-animated fade-button">
                <div class="visible-content btn-icon">
                    <img src="./assets/img/icons/checkin-time.png" alt="image" width="40" />
                    <!-- <span class="material-icons  font-30  sidebar-item-icon-material">work</span> -->
                    <div>Lịch dạy</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content  text-menu font-weight-bold">
                    Khai báo lịch dạy</div>
            </button>
            <button routerLink="/class-time" class="btn  btn-white m-2 btn-animated fade-button ">
                <div class="visible-content btn-icon ">
                    <img src="./assets/img/icons/calendar.png" alt="image" width="40" />
                    <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                    <div>Lịch học</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                    Khai báo lịch học</div>
            </button>
            <button routerLink="/meal-time" class="btn  btn-white m-2 btn-animated fade-button ">
                <div class="visible-content btn-icon ">
                    <img src="./assets/img/icons/calendar.png" alt="image" width="40" />
                    <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                    <div>Bán trú</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                    Khai báo lịch bán trú</div>
            </button>
            <button routerLink="/holiday" class="btn  btn-white m-2 btn-animated fade-button ">
                <div class="visible-content btn-icon ">
                    <img src="./assets/img/icons/calendar.png" alt="image" width="40" />
                    <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                    <div>Ngày nghỉ</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                    Khai báo ngày nghỉ</div>
            </button>
            <button routerLink="/slogan" class="btn  btn-white m-2 btn-animated fade-button ">
                <div class="visible-content btn-icon ">
                    <img src="./assets/img/icons/file-and-folder.png" alt="image" width="40" />
                    <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                    <div>Slogan</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                    Khai báo Slogan</div>
            </button>
            <button routerLink="/scholastic" class="btn  btn-white m-2 btn-animated fade-button ">
                <div class="visible-content btn-icon ">
                    <img src="./assets/img/icons/on-fire.png" alt="image" width="40" />
                    <!-- <span class="material-icons font-30 sidebar-item-icon-material ">rule</span> -->
                    <div>Năm học</div>
                </div>
                <div
                    class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold ">
                    Khai báo năm học</div>
            </button>

        </div>
        <li class="heading"><span class="nav-label">Cài đặt</span></li>
        <button routerLink="/setting" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/productive.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">settings</span> -->
                <div>Cài đặt</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Cài
                đặt chung</div>
        </button>
        <button routerLink="/camera" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/camera.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Camera AI</div>
            </div>
            <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Cài
                đặt Camera AI</div>
        </button>
        <button [disabled]="true" routerLink="/camera-student" class="btn  btn-white m-2 btn-animated fade-button">
            <div class="visible-content btn-icon">
                <img src="./assets/img/icons/camera-student.png" alt="image" width="40" />
                <!-- <span class="material-icons  font-30  sidebar-item-icon-material">calendar_month</span> -->
                <div>Học sinh</div>
            </div>
            <!-- <div class="d-flex align-items-center  justify-content-center hidden-content text-menu font-weight-bold">Gán camera Điểm danh</div> -->
        </button>

        <!-- <li class="heading "><span class="nav-label ">------</span></li>




        <li routerLinkActive="active ">
            <a routerLink="/promotion ">
                <span class="material-icons sidebar-item-icon-material ">redeem</span>
                <span class="nav-label ">Khuyến mãi</span>
            </a>
        </li>

        <li>

            <a href="# " class="has-arrow ">
                <span class="material-icons sidebar-item-icon-material ">mood</span>
                <span class="nav-label ">Khách
                    hàng</span>
            </a>
            <ul class="nav-2-level ">
                <li routerLinkActive="active "><a routerLink="/customer"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Danh sách</span></a></li>
                <li routerLinkActive="active "><a routerLink="/customer-cate"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Nhóm KH</span></a></li>
            </ul>
        </li>
        <li>
            <a href="# " class="has-arrow ">
                <span class="material-icons sidebar-item-icon-material ">category</span>
                <span class="nav-label ">Kho hàng</span>
            </a>
            <ul class="nav-2-level ">
                <li routerLinkActive="active ">
                    <a routerLink="/stockincart"> <i class="ti ti-minus pr-2 "></i><span class="nav-label ">Nhập
                            hàng</span></a>
                </li>
                <li routerLinkActive="active "><a routerLink="/stockin"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Phiếu nhập hàng</span></a></li>
                <li routerLinkActive="active "><a routerLink="/inventory"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Kiểm kê</span></a></li>
                <li routerLinkActive="active "><a routerLink="/inventory-list"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Phiếu kiểm kê</span></a></li>
            </ul>
        </li>
        <li>
            <a href="# " class="has-arrow ">
                <span class="material-icons sidebar-item-icon-material ">monetization_on</span>
                <span class="nav-label ">Công nợ</span>
            </a>
            <ul class="nav-2-level ">
                <li routerLinkActive="active ">
                    <a routerLink="/debt"> <i class="ti ti-minus pr-2 "></i><span class="nav-label ">Nhân viên</span></a>
                </li>
                <li routerLinkActive="active "><a routerLink="/debt-customer"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Khách hàng</span></a></li>
            </ul>
        </li>

        <li routerLinkActive="active ">
            <a routerLink="/membership">
                <span class="material-icons sidebar-item-icon-material ">supervisor_account</span>
                <span class="nav-label ">Nhân viên</span>
            </a>
        </li>

        <li>

            <a href="# " class="has-arrow ">
                <span class="material-icons sidebar-item-icon-material ">bar_chart</span>
                <span class="nav-label ">Báo cáo</span>
            </a>
            <ul class="nav-2-level ">
                <li routerLinkActive="active ">
                    <a routerLink="/warehouse"> <i class="ti ti-money pr-2 "></i><span class="nav-label ">Tồn
                            kho</span></a>
                </li>
                <li routerLinkActive="active ">
                    <a routerLink="/report2"> <i class="ti ti-minus pr-2 "></i><span class="nav-label ">Hóa đơn</span></a>
                </li>
                <li routerLinkActive="active "><a routerLink="/report"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Báo cáo ngày</span></a></li>
                <li routerLinkActive="active "><a routerLink="/report-month"><i class="ti ti-minus pr-2 "></i><span
                            class="nav-label ">Báo cáo tháng</span></a></li>
            </ul>
        </li>
        <li class="heading "><span class="nav-label ">Cài đặt</span></li>

        <li routerLinkActive="active ">
            <a routerLink="/setting">
                <span class="material-icons sidebar-item-icon-material ">settings</span>
                <span class="nav-label ">Cài đặt</span>
            </a>
        </li> -->
        <!-- <span class="version-app">v{{version}}</span> -->
    </div>





    <!-- <ul class="side-menu " metis-menu> -->

</div>