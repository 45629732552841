import { Component, Inject, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { debounceTime, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from 'src/app/model/product';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeacherService } from 'src/app/_services/teacher.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { TeacherPickerComponent } from 'src/app/components/teacher-picker/teacher-picker.component';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-teacher-picker-multi',
  templateUrl: './teacher-picker-multi.component.html',
  styleUrls: ['./teacher-picker-multi.component.css']
})
export class TeacherPickerMultiComponent implements OnInit {
  loading = false;
  display_type = 0;
  //for search and scanner
  searchValue = "";
  productCodeTemp = "";
  // searchStr: string;
  searchFailed = false;

  //for data
  classSelected: { code: "" , name: "" };
  teacher_list = [];
  teachers_backup: any;
  categorys: any;
  facultys: any;
  categorys_brand: any;
  filter = {
    name: "",
    positions: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }


  //for paging
  viewNumsObj = this.appService.viewNums[5].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;



  // products = [];
  products_backup: any
  categories: any
  sorter = {
    sortStr: "",
  }

  // for add product
  // product = new Product("");
  // for update product
  teacherSelected: any;
  isDeleted: boolean;

  //for cart
  productCart = {
    name: "",
    code: "",
    price: 0,
    price_in: 0,
    description: "",
  };
  txt_quantity = 1;


  constructor(
    public appService: AppService,
    public teacherService: TeacherService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<TeacherPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {


    this.sorter.sortStr = this.appService.sort_products[0].code;
    if (localStorage.getItem(environment.display_type)) {
      this.display_type = parseInt(localStorage.getItem(environment.display_type));
    }

    // if(this.appService.products.length == 0){
    this.loadTeacher();
    // this.loadCategoryBrand();
    // this.loadCategory();

    this.sharedService.getClickEvent().subscribe((type) => {
      console.log("Sale component:", type);
      this.loadTeacher();
    })

  }



  ngOnInit(): void {
    this.classSelected = this.data.classSelected;
    this.loadFaculty();
    console.log(this.classSelected);
  }

  loadTeacher() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.teacherService.loadTeacher(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            

            //thêm selected_temp để cho action
            response.data.forEach(element => {
              // nếu được chọn là 1  thì hiển thị 
              if(this.data.data[0]['teacher_codes'] != null){
                if(this.data.data[0]['teacher_codes'].includes(element.code)){
                  element.selected_temp = true;
                }else
                element.selected_temp = false;
              }else{
                element.selected_temp = false;
              }

              // kiểm tra nếu giáo viên chủ nhiệm thì  bỏ chọn và disable
              if(this.data.classSelected.chunhiem_code == element.code){
                element.selected_temp = false; // force bỏ chọn
                element.temp_disable = true; // disable để không được chọn
              }else{
                element.temp_disable = false;
              }
            
            });


            this.teacher_list = response.data;
            console.log(this.teacher_list);
            // this.teachers_backup = response.data;
            // this.appService.teachers = response.data;
            // this.appService.teachers_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.teacher_list.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

          }
        },
        error => {

        })
    }
  }
  async loadFaculty() {
    let filter = {
      type: environment.cateType_faculty
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }



  ngAfterViewInit() {
    // dropdown danh mục

  }



  resetProduct() {
    let temp_unit = "";
    if (this.appService.units.length > 0)
      temp_unit = this.appService.units[0].code;



  }

  async loadCategory() {

    let filter = {
      type: environment.cate_type
    };

    this.categoryService.loadAllCategory(filter).subscribe(
      async response => {
        // this.loading.dismiss();
        console.log(response);
        if (response) {
          this.categories = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.loading.dismiss();
        console.error(error);
      })

  }


  category_change(event) {
    this.loadTeacher();
  }
  select_category(code) {
    console.log(code);
    this.filter.category_code = code;
    this.loadTeacher();
  }
  sort_change(event) {
    this.loadTeacher();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.appService.products = this.appService.products_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.appService.products = this.appService.products.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  


  onCancelClick(): void {
    this.dialogRef.close();
  }
  click_select(): void {
    var teacher_codes = [];
    this.teacher_list.forEach(element => {
      if (element.selected_temp) {
        teacher_codes.push({
          code : element.code,
          name : element.name
        });
      }
    });

    if (teacher_codes.length == 0) {
      this.toastr.warning("", "Bạn chưa chọn giáo viên");
      return;
    }
    this.dialogRef.close(teacher_codes);
  }

  isLoading_ggvcn = false;
  

  //for paging
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.appService.viewNumsObj);
    this.loadTeacher();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadTeacher();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadTeacher();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadTeacher();
    }
  };






  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        // this.filter.code = this.filter.name;
        console.log(this.filter.name);
        this.loadTeacher();
      }),
      map(term => term === '' ? []
        : this.appService.products.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => {

      }),
    )

  onClick(r) {
    console.log(r);
    this.filter.name = "";
    console.log("this.filter.name", this.filter.name);
  }


 
  mouseover(item) {
    console.log("mouseover", item)
  }
  mouseout(item) {
    console.log("mouseout", item)
  }
}

